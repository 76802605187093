import React, { useEffect, useState } from "react"
import BeneficiaryList from './beneficiaryList';

import { isNumber } from "../../Utils/Common";
import { toast } from 'react-toastify';
import PropagateLoader from "react-spinners/PropagateLoader";
import { POST_DMT_PAY_SPRINT_CUSTOMER_DETAIL } from '../../Utils/appConstants';
import { postRequest } from '../../Utils/AppApi';
import { useHistory } from "react-router-dom";
import moment from "moment";
import { Modal, DatePicker } from "antd";
import DmtAadharVerify from '../dmtAadharVerify';

function ViewCustomer(props) {
    const dateFormat = 'DD-MM-YYYY';
    const history = useHistory();
    const [customerData, setCustomerData] = useState(null);
    const [isLoading, setLoading] = useState(false);
    // const [isDataRequired, setDataRequired] = useState(false);
    const [isModalEdit, setModalEdit] = useState(false);
    const [getDataError, setDataError] = useState({});
    const [getDataField, setDataField] = useState({});
    const [dateOfBirth, setDateOfBirth] = useState('');
    const [isAadharModal, setAadharModal] = useState(false);

    useEffect(() => {
        setCustomerData(props.isCustomerInfo);
        let lastDate = moment().subtract(19, 'years');
        let dateValue = moment(lastDate);
        // console.log("useEffect --------------",dateValue)
        // console.log("props.isCustomerInfo-------", props.isCustomerInfo)
        setDataField({ dateOfBirth: dateValue.format('DD-MM-YYYY') })
        setDateOfBirth(dateValue)
        // if (!props.isCustomerInfo.isVerified) {
        //     setDataField({ ...getDataField, customerDob: dateValue });
        // }

        if (props.isCustomerInfo && (!props.isCustomerInfo.area || !props.isCustomerInfo.pincode || !props.isCustomerInfo.customerDob)) {
            setModalEdit(true);
        }
    }, [props.isCustomerInfo]) // eslint-disable-line react-hooks/exhaustive-deps

    const FormValidate = () => {
        let formValid = true;
        let formObject = {};
        setDataError({})
        if (getDataField && getDataField.length === 0) {
            formValid = false;
        }
        console.log("getDataField --------------",getDataField)
        let array = ["address", "pincode", "dateOfBirth"];
        array.forEach(element => {
            if (getDataField[element] === "" || typeof getDataField[element] === "undefined") {
                formObject[element] = true;
                formValid = false;
            }
        });
        setDataError(formObject);
        return formValid;
    }

    const onChangeDateOfBirth = (date, dateString) => {
        console.log("-------", dateString);
        setDateOfBirth(date);
        setDataField({ ...getDataField, dateOfBirth: dateString });
    };

    const onChangeValue = (event) => {
        const { name, value } = event.target;
        setDataField({ ...getDataField, [name]: value });
    }

    const handleCustomerEditDetail = () => {
        if (FormValidate() === true) {
            setLoading(true)
            // console.log("========== otpCode", otpCode)
            const payload = {
                id: customerData._id,
                pincode: getDataField.pincode,
                address: getDataField.address,
                customerDob: getDataField.dateOfBirth
            };

            postRequest(`${POST_DMT_PAY_SPRINT_CUSTOMER_DETAIL}`, payload, history).then((response) => {
                if (response.success === true) {
                    // handleAddBeneficiary()
                    setModalEdit(false)
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
                setLoading(false)
            }).catch(function (error) {
                // console.log(error)
                setModalEdit(false)
                setLoading(false)
            })
        } else {
            toast.error("Field is required");
        }
    }

    if (isLoading) {
        return <div className="content text-center" style={{ margin: "250px 0" }}>
            <PropagateLoader color="#5851D2" loading={isLoading} size={25} />
        </div>
    }

    return (
        <section>
            <div>
                <div className="row d-flex align-items-center mh-100">
                    <div className="col p-0 col-xl-12">
                        <div className="card" style={{ borderRadius: 15 }}>
                            <div className="card-body m-0">
                                {customerData && <><h3 className="mb-3">{customerData.customerName}</h3>
                                    <p className="mb-0 fx-14 text-dark">
                                        <i className="fas fa-star fa-lg text-warning" />
                                        <span className="mx-2">|</span>
                                        Mobile : <strong>{customerData.customerMobile}</strong>
                                        {<><span className="mx-2">|</span>
                                            Available Limit : <strong>{customerData.availableLimit}</strong></>}
                                        <span className="mx-2">|</span>
                                        Status : <strong>{customerData.pipeInstantTransfer ? customerData.pipeInstantTransfer : "N"}</strong>
                                        <span className="mx-2">|</span>
                                        {customerData && customerData.pipeInstantTransfer === "N" && <button className="btn btn-sm btn-primary" onClick={() => setAadharModal(true)}>Instant Transfer KYC</button>}
                                        {/* {!customerData.isVerified && <button className="btn btn-xs btn-info">Verify Customer</button>} */}
                                    </p>
                                    <hr className="my-2" /></>}
                                    {isAadharModal && <DmtAadharVerify serviceName={"DMT2"} mobile={customerData.customerMobile} aadhar={customerData.aadhar} findCustomerInfo={props.findCustomerInfo}  />}

                                {customerData && customerData.isVerified && <BeneficiaryList customerData={customerData} />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {isModalEdit && <Modal title={"Update Customer Detail"} width={600} open={isModalEdit} onOk={() => handleCustomerEditDetail()} onCancel={() => { window.location.reload()}} okText={"Submit"} >
                <div className="form-group">
                    <label className="small mb-1" >Date Of Birth <i className="text-danger">*</i></label>
                    <DatePicker className="form-control" value={dateOfBirth} format={dateFormat} onChange={onChangeDateOfBirth} defaultValue={moment()}  disabledDate={(current) => {
                        let customDate = moment(moment().subtract(19, 'years')).format("YYYY-MM-DD");
                        return current && current > moment(customDate, "YYYY-MM-DD");
                    }} />
                    {getDataError && getDataError.dateOfBirth && <span className="error text-danger">The field is required</span>}
                </div>
                <div className="form-group">
                    <label className="small mb-1" >Address <i className="text-danger">*</i></label>
                    <input className="form-control" type="text" placeholder="Address" name="address" value={getDataField.address} onChange={(e) => onChangeValue(e)} maxLength={40} />
                    {getDataError && getDataError.address && <span className="error text-danger">The field is required</span>}
                </div>
                <div className="form-group">
                    <label className="small mb-1" >Pincode <i className="text-danger">*</i></label>
                    <input className="form-control" type="text" placeholder="Pincode" name="pincode" value={getDataField.pincode} onChange={(e) => onChangeValue(e)} maxLength={6} onKeyDown={isNumber} />
                    {getDataError && getDataError.pincode && <span className="error text-danger">The field is required</span>}
                </div>
            </Modal>}
        </section>
    )
}

export default ViewCustomer;