
import React, { useEffect, useState } from "react";
import { isNumber } from "../../Utils/Common";
import PropagateLoader from "react-spinners/PropagateLoader";
import { POST_DMT_INSTANT_PAY_CREATE_CUSTOMER, POST_DMT_INSTANT_PAY_OTP_VERIFICATION, GET_AEPS_DEVICE_CONFIG, POST_DMT_INSTANT_PAY_CUSTOMER_KYC } from '../../Utils/appConstants';
import { useHistory } from "react-router-dom";
import { getRequest, postRequest } from '../../Utils/AppApi';
import { ToastContainer, toast } from 'react-toastify';
import moment from "moment";
import { Checkbox, Modal } from 'antd';
import axios from 'axios';
import xmlJs from 'xml-js';
import BioDevice from '../aeps2/bioDevice';
import DmtAadharVerify from '../dmtAadharVerify';
import { encryptAadhar } from "../../Utils/function";

function AddCustomer(props) {
    const history = useHistory();
    const customerMobile = props && props.mobile;
    const [isLoading, setLoading] = useState(false);
    const [isOtpModal, setOtpModal] = useState(false);
    const [otpCode, setOtpCode] = useState(null)
    const [referenceKey, setReferenceKey] = useState(null);
    const [getDataError, setDataError] = useState({});
    const [getDataField, setDataField] = useState({});
    const [otpSent, setOtpSent] = useState(false);
    const [resendTime, setResendTime] = useState(0);

    const [isConsent, setConsent] = useState("Y");
    const [isBioAuthModel, setBioAuthModel] = useState(false);
    const [instantTransferModal, setInstantTransferModal] = useState(false);
    const [isAadharModal, setAadharModal] = useState(false);
    const [aadharNumber, setAadharNumber] = useState("");

    const [isErrorAuth, setErrorAuth] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [fingerprintXmlData, setFingerprintXmlData] = useState(null)
    const [deviceConfig, setDeviceConfig] = useState({});
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [biometricData, setBiometricData] = useState(null);

    const onChangeOtpValue = (value) => {
        setOtpCode(value);
    }

    const FormValidate = () => {
        let formValid = true;
        let formObject = {};
        setDataError({})
        if (getDataField && getDataField.length === 0) {
            formValid = false;
        }
        let array = ["customerMobile", "aadharNumber"];
        // let array = ["customerName", "customerMobile", "pincode", "aadharNumber"];

        array.forEach(element => {
            if (getDataField[element] === "" || typeof getDataField[element] === "undefined") {
                formObject[element] = true;
                formValid = false;
            }
        });

        // if (getDataField && getDataField.customerName) {
        //     let name = getDataField.customerName.split(" ");

        //     if (!name[1]) {
        //         formObject.customerName = 2;
        //         formValid = false;
        //     }
        // } else {
        //     formObject.customerName = 1;
        //     formValid = false;
        // }

        setDataError(formObject);
        return formValid;
    }

    const onChangeValue = (event) => {
        const { name, value } = event.target;
        setDataField({ ...getDataField, [name]: value });
    }

    const handleSubmit = () => {
        // console.log("1========== submit")
        // console.log("1========== FormValidate()", FormValidate())
        // console.log("1========== FormValidate()", getDataField)
        if (FormValidate() === true) {
            setLoading(true)
            // console.log("========== submit")
            const payload = {
                customerMobile: customerMobile,
                customerName: getDataField.customerName,
                pincode: getDataField.pincode,
                aadhar: getDataField.aadharNumber,
                referenceKey: props.referenceKey
            };
            setAadharNumber(getDataField.aadharNumber && encryptAadhar(getDataField.aadharNumber));
            postRequest(`${POST_DMT_INSTANT_PAY_CREATE_CUSTOMER}`, payload, history).then((response) => {
                if (response.success === true) {
                    if (response.isotp === true) {
                        setOtpModal(true);
                    } else {
                        props.findCustomerInfo();
                    }
                    setReferenceKey(response.data.referenceKey);
                    console.log("response========== ", response)
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
                handleResendOtp();
                setLoading(false)
            }).catch(function (error) {
                // console.log(error)
                setLoading(false)
            })
        }
    }

    const handleResendOtp = () => {
        // Simulate sending OTP
        console.log('OTP sent!');
        setOtpSent(true);

        // Set a timer for 2 minutes (in seconds)
        const timerDuration = 2 * 60;
        setResendTime(timerDuration);

        const resendTimer = setInterval(() => {
            setResendTime(prevTime => (prevTime > 0 ? prevTime - 1 : 0));
        }, 1000); // Update the timer every second

        // Additional logic for resending OTP can be added here
        // Cleanup the timer on component unmount or if you navigate away
        return () => {
            clearInterval(resendTimer);
        };
    };

    const handleCustomerVerify = () => {
        console.log("========== verify")
        if (otpCode) {
            setLoading(true)
            console.log("========== otpCode", otpCode)
            const payload = {
                referenceKey: referenceKey,
                otp: otpCode,
                customerMobile: customerMobile
            };

            postRequest(`${POST_DMT_INSTANT_PAY_OTP_VERIFICATION}`, payload, history).then((response) => {
                if (response.success === true) {
                    setOtpModal(false);
                    setBioAuthModel(true);
                    // props.findCustomerInfo();
                    setReferenceKey(response.data.referenceKey);
                    toast.success(response.message);
                } else {
                    toast.error(response.message);
                }
                setLoading(false)
            }).catch(function (error) {
                // console.log(error)
                setLoading(false)
            })
        } else {
            toast.error("OTP code is required");
        }
    }

    const handleCloseModal = () => {
        setBioAuthModel(false)
    }

    const onChangeChackBox = (e) => {
        if (e.target.checked === true) {
            setConsent('Y')
        } else {
            setConsent('N')
        }
    };

    const scanFingerPrintData = async () => {
        await axios({
            method: 'CAPTURE',
            url: deviceConfig.captureUrl,
            headers: { "Content-Type": "text/xml; charset=UTF-8" },
            data: deviceConfig.rdServiceFPData
        }).then(response => {
            // console.log("00----------------------", response.data)
            const jsonData = xmlJs.xml2json(response.data, { compact: true, spaces: 4 });
            let XMLjson = JSON.parse(jsonData);

            if (XMLjson && XMLjson.PidData.Resp._attributes.errCode !== "700") {
                setFingerprintXmlData(response.data)
            } else {
                setFingerprintXmlData(null)
            }
        }).catch(error => {
            console.log("error -----------", error)
        })
    }

    const aepsConfig = () => {
        getRequest(GET_AEPS_DEVICE_CONFIG, history).then((response) => {
            if (response.success === true) {
                // console.log("------response--------------", response)
                setDeviceConfig(response.data)
                // toast.success(response.message);
            }
            else {
                setBioAuthModel(true)
                setErrorAuth(false)
                setErrorMessage(response.message)
            }
        })
    }

    const FingerValidate = () => {
        let formValid = true;
        let formObject = {};

        if (isConsent === 'N') {
            toast.error("Please aadhaar accept consent declaration");
            formValid = false;
        }

        setDataError(formObject);
        return formValid;
    }

    const scanFinger = () => {
        if (FingerValidate()) {
            scanFingerPrintData()
        }
    }

    const handleCustomerKyc = () => {
        setLoading(true)
        const payload = {
            customerMobile: customerMobile,
            biometricData: biometricData,
            referenceKey: referenceKey,
            latitude: latitude,
            longitude: longitude,
            isXml: true,
            consent: isConsent
        }

        postRequest(POST_DMT_INSTANT_PAY_CUSTOMER_KYC, payload, history).then((response) => {
            if (response.success === true) {
                if(response.isUpdatedKyc === true) {
                    props.findCustomerInfo();
                } else {
                    // let record = response.data;
                    setBioAuthModel(false);
                    setInstantTransferModal(true);
                }
                // props.findCustomerInfo();
                toast.success(response.message);
            } else {
                toast.error(response.message);
            }
            setLoading(false)
        });
    }

    useEffect(() => {
        navigator.geolocation.getCurrentPosition((data) => {
            setLatitude(data.coords.latitude);
            setLongitude(data.coords.longitude);
        });
        aepsConfig();
        let lastDate = moment().subtract(19, 'years');
        let dateValue = moment(lastDate).format('YYYY-MM-DD');
        setDataField({ ...getDataField, customerMobile: customerMobile, dateOfBirth: dateValue });
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (fingerprintXmlData) {
            // console.log("fingerprint ---", fingerprintXmlData)
            // let data = biometricDataToJson(fingerprintXmlData)
            setBiometricData(fingerprintXmlData)
        }
    }, [fingerprintXmlData]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (biometricData) {
            // console.log("call cash withdrawal api ---")
            handleCustomerKyc();
        }
    }, [biometricData]) // eslint-disable-line react-hooks/exhaustive-deps

    const formatTime = seconds => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    if (isLoading) {
        return <div className="content text-center" style={{ margin: "250px 0" }}>
            <PropagateLoader color="#5851D2" loading={isLoading} size={25} />
        </div>
    }
    return (
        <div className="row">
            <div className="col-xl-12">
                {/* Account details card*/}
                <div className="card m-0 ">
                    {/* <div className="card-header m-0"></div> */}
                    <div className="card-body m-0 py-0">
                        {/* <div className="row gx-3 mb-3">
                            <div className="col-md-6">
                                <label className="small mb-1" >Name</label>
                                <input className="form-control" type="text" placeholder="Enter First Name & Last Name" name="customerName" value={getDataField.customerName} onChange={(e) => onChangeValue(e)} minLength={4} maxLength={20} />
                                {getDataError && getDataError.customerName === 1 && <span className="error text-danger">The field is required</span>}
                                {getDataError && getDataError.customerName === 2 && <span className="error text-danger">Please enter customer first name & last name</span>}
                            </div>
                            <div className="col-md-6">
                                <label className="small mb-1" >PinCode</label>
                                <input className="form-control" type="text" placeholder="Pin Code" name="pincode" onKeyDown={isNumber} onChange={(e) => onChangeValue(e)} value={getDataField.pincode} maxLength={6} />
                                {getDataError && getDataError.pincode && <span className="error text-danger">The field is required</span>}
                            </div>
                        </div> */}
                        <div className="row gx-3 mb-3">
                            <div className="col-md-6">
                                <label className="small mb-1" >Aadhar Number</label>
                                <input className="form-control" type="text" placeholder="Enter Aadhar Number" name="aadharNumber" value={getDataField.aadharNumber} onChange={(e) => onChangeValue(e)} maxLength={12} onKeyDown={isNumber} />
                                {getDataError && getDataError.aadharNumber && <span className="error text-danger">The field is required</span>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-12">
                <button className="btn btn-primary float-right" onClick={() => handleSubmit()} type="button">Add Customer</button>
                <ToastContainer position="top-center" autoClose={5000} limit={2} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="colored" />
            </div>
            {isOtpModal && <Modal title={"Customer Verification OTP"} width={600} open={isOtpModal} onOk={() => handleCustomerVerify()} onCancel={() => setOtpModal(false)} okText={"Submit"} >
                <div className="form-group">
                    <label>OTP Code</label>
                    <div className="input-group">
                        <div className="custom-file">
                            <input type="text" className="form-control" name="otpCode" placeholder="OTP Code" onChange={(e) => onChangeOtpValue(e.target.value)} autoComplete="off" value={otpCode} maxLength={6} onKeyDown={isNumber} />
                        </div>
                    </div>
                    <div className="text-right">
                        {otpSent && <p>Resend in: {formatTime(resendTime)}</p>}
                        <button type='button' onClick={() => handleSubmit()} className='btn btn-sm btn-warning mt-2 text-right' disabled={otpSent}>Resend OTP</button>
                    </div>
                </div>
            </Modal>}

            {isBioAuthModel && <Modal title={"Customer KYC"} width={600} open={isBioAuthModel} onCancel={() => handleCloseModal()} footer={false} >
                {isErrorAuth ?
                    <div><p className='text-danger'>{errorMessage && errorMessage.replace('please try again later', 'Please wait until Bank approval')}</p>
                    </div>
                    :
                    <>
                        <div className='text-center h-100'>
                            <img src={'./../fingerprint-svgrepo-com.svg'} alt='' width={'175px'} />
                        </div>
                        <div className='col-sm-12 col-md-12 col-lg-12 mb-120'>
                            <div className='form-group'>
                                <Checkbox className='d-flex' checked={isConsent} onChange={onChangeChackBox}>I hereby give my voluntary consent to SR PAY Private Limited, use my Aadhaar/ VID number & biometric to authenticate from UIDAI for the purpose of AEPS transaction.</Checkbox>
                            </div>
                        </div>
                        <BioDevice />
                        <button className='btn btn-primary btn-lg bg-primary btn-block' onClick={() => scanFinger()}>Scan Finger</button></>}
            </Modal>}

            {instantTransferModal && <Modal title={"Instant Transfer Service"} width={600} open={instantTransferModal} onOk={() => {
                setInstantTransferModal(false);
                setAadharModal(true);
                }} onCancel={() => {setInstantTransferModal(false); props.findCustomerInfo()} } okText={"Process to Continue"} >
                <div className="form-group">
                Do you want to  also process KYC for Instant Money Transfer?
                </div>
            </Modal>}

            {isAadharModal && <DmtAadharVerify serviceName={"DMT1"} mobile={customerMobile} aadhar={aadharNumber} findCustomerInfo={props.findCustomerInfo} />}
        </div>
    )
}

export default AddCustomer;
